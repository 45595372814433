<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>

    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <label>From</label>
          <b-form-datepicker
            :value="dateStart"
            :close-button="true"
            placeholder="Start Date"
            local="en"
            class="mr-2"
            @input="(val) => $emit('update:dateStart', val)"
          />
        </b-col>
        
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>To</label>
          <b-form-datepicker
            placeholder="End Date"
            local="en"
            :value="dateEnd"
            :min="dateStart"
            @input="(val) => $emit('update:dateEnd', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2 ml-auto"
        >
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormDatepicker,
  BFormInput, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'

import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormDatepicker,
    BFormInput, 
    BInputGroup, 
    BInputGroupAppend
  },
  props: {
    showSearchFilter: {
      type: Boolean,
      default: false
    },
    dateStart: {
      type: [String, null],
      default: null
    },
    dateEnd: {
      type: [String, null],
      default: null
    },
    userFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
