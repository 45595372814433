<template>
  <b-overlay :show="loading">

    <div class="">
      <div class="d-none d-md-block mt-md-4">
        <list-table
            :records="manual_repayments"
            :columns="tableColumns"
            :current-page-number.sync="currentPage"
            :total-records="manual_repayments.length"
            :show-filter-toggle-button="false"
            :show-search="false"
            @row-clicked="onRowClicked"
>
          <template #filters>
            <repayments-filter
                :date-start.sync="dateStart"
                :date-end.sync="dateEnd"
                :status.sync="statusFilter"
            >

            </repayments-filter>
          </template>
          <template #cell(status)="data">
            <b-badge
                pill
                :variant="`light-${resolveStatusVariant(data.item.status)}`"
                class="text-capitalize my-1"
            >
              {{ getUserStatusText(data.item.status) }}
            </b-badge>
          </template>
          <template #cell(_id)="data">
            <!-- Assume we paid full amount if repayment is not matched to manual repayment - That is the case with legacy repayments created for manual repayments -->
            <div v-if="data.item.repayment">
              ¢{{ formatMoney(getValueFromSource(data, 'item.repayment.paid_amount')) }}
            </div>
            <span v-else>
              ¢{{ formatMoney(getValueFromSource(data, 'item.balance_to_be_paid')) }}
            </span>
          </template>
        </list-table>
      </div>
      
      <div class="d-md-none">
        <div>
          <repayments-filter
              :date-start.sync="dateStart"
              :date-end.sync="dateEnd"
              :status.sync="statusFilter"
          >

          </repayments-filter>

        </div>
        <div v-for="(repayment, i) in manual_repayments" :key="i">
          <div class=" border-bottom">
            <div>
              <div class="d-flex align-items-center justify-content-between mb-50">
                <div>
                  <h4 class="p-0 m-0">
                    Ghc {{ getValueFromSource(repayment, 'loan.total_loan_cost') }}
                  </h4>
                </div>
                <div>
                  <b-badge
                      pill
                      :variant="`light-${resolveStatusVariant(getValueFromSource(repayment, 'status'))}`"
                      class="text-capitalize my-1"
                  >
                    {{ getUserStatusText(getValueFromSource(repayment, 'status')) }}
                  </b-badge>
                </div>
              </div>


              <div class="mb-1">
                <small>Amt Paid</small>
                <h1> <small class="text-black">Ghc </small> {{ formatMoney(getValueFromSource(repayment, 'repayment.paid_amount') ) }}</h1>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <div class="d-flex align-items-center">
                    <small class="mr-1">balance: </small>
                    <h5 class="p-0 m-0">
                      GHc {{ formatMoney(getValueFromSource(repayment, 'balance_to_be_paid')) }}
                    </h5>
                  </div>
                  <div class="d-flex align-items-center">
                    <small class="mr-1">month: </small>
                    <h5 class="p-0 m-0">
                      {{ (getValueFromSource(repayment, 'submitted_month')) }}
                    </h5>
                  </div>
                </div>
                <div>
                  <router-link :to="{ name: 'single-manual-repayment', params: { manual_repayment_id: getValueFromSource(repayment, '_id') } }">
                    <b-button variant="primary">
                      Details
                    </b-button>
                  </router-link>
                </div>
              </div>
              <div class="mt-2">
                <p class="border-left pl-1">
                  {{ formatDate(getValueFromSource(repayment, 'created'), 'dd MMMM yyyy') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import RepaymentsFilter from "@/pages/client/manual-repayments/repayments-filter.vue";
import ListTable from "@core/components/ListTable/ListTable.vue";
import {
  BMedia, BAvatar, BButton, VBTooltip,
  BOverlay, BCard, BRow, BCol, BAlert, BLink, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination
} from 'bootstrap-vue'

import vSelect from 'vue-select'

import { debounce, get } from "lodash"
import { formatDate, formatMoney } from "@core/utils/utils"

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ManualRepaymentsListFilter from '@core/components/shared/manual-repayments/ManualRepaymentsListFilter.vue'

const watchHandler = {
  handler(){
    this.debounceFetchAllData()
  }, 
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler() {
    this.currentPage = 1;
    this.debounceFetchAllData()
  }
}

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    RepaymentsFilter,
    ListTable,
    BOverlay,
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,

    vSelect,
    ManualRepaymentsListFilter
  },
  props: {
    tableColumns: {
      type: Array,
      default: () => {
        return [
          { key: 'loan.total_loan_cost', sortable: false, label: 'Original Loan Cost', formatter: val => `¢${formatMoney(val)}` },
          { key: 'balance_to_be_paid', sortable: false, label: 'Remaining Balance', formatter: val => `¢${formatMoney(val)}` },
          { key: 'status', sortable: false },
          { key: '_id', sortable: false, label: 'Amount Paid' },
          { key: 'submitted_month', sortable: false },
          { key: 'created', label: 'Submitted Date', sortable: false, formatter: val => `${formatDate(val)}` },
        ]
      }
    },
    showFilters: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return {
      loading: false,
      loan_id: "",
      manual_repayments: [],

      dateEnd: null,
      dateStart: null,
      statusFilter: null,
      debounceFetchAllData: null
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
    dataMeta(){
      const localItemsCount = this.manual_repayments.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRecords,
      }
    },

  },
  watch: {
    perPage: watchHandlerRequiresPageReset,
    loan_id: watchHandlerRequiresPageReset,
    currentPage: watchHandler,
    statusFilter: watchHandlerRequiresPageReset,
    dateStart: watchHandlerRequiresPageReset,
    dateEnd: watchHandlerRequiresPageReset
  },
  created(){
    this.debounceFetchAllData = debounce(this.fetchAllData, 500);

    const {
      status,
      page = 1,
      limit = this.perPage
    } = this.$route.query;

    this.currentPage = +page;
    this.statusFilter = status;
    this.perPage = +limit;
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true; 

        const query = {
          limit: this.perPage,
          page: this.currentPage,
          from: this.dateStart,
          to: this.dateEnd,
          status: this.statusFilter,
          loan_id: this.loan_id
        }
        this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().clientService.fetchManualRepayments(query);
        const { data, pagination } = request.data;
        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;

        this.manual_repayments = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    onRowClicked(rowItem){
      this.$router.push({ name: 'single-manual-repayment', params: { manual_repayment_id: rowItem._id } })
      .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  .width-100 {
    width: 100px;
  }
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  .loan-list-tb-row {
    cursor: pointer !important;
  }
</style>

