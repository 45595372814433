<template>
  <section>
    <b-row class="mb-2">
      <b-col cols="12" md="3" class="mt-50">
        <label>From</label>
        <b-form-datepicker
            class="mr-2"
            local="en"
            size="sm"
            placeholder=""
            :value="dateStart"
            :disabled="loading"
            @input="(val) => $emit('update:dateStart', val)"
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-50">
        <label>To</label>
        <b-form-datepicker
            local="en"
            placeholder=""
            size="sm"
            class=""
            :value="dateEnd"
            :min="dateStart"
            :disabled="loading"
            @input="(val) => $emit('update:dateEnd', val)"
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-50">
        <label>Status</label>
        <v-select
            class="w-100"
            :value="status"
            :disabled="loading"
            :options="statusOptions"
            :reduce="val => val.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            @input="(val) => $emit('update:status', val)"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BFormDatepicker } from "bootstrap-vue";
import vSelect from 'vue-select'


export default {
  name: "RepaymentsFilter",
  components: {
    BRow,
    BCol,
    vSelect,
    BFormDatepicker
  },
  props: {
    dateStart: {
      type: [String, null],
      default: null
    },
    dateEnd: {
      type: [String, null],
      default: null
    },
    status: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      searchResults: [],
      statusOptions: [
        { label: 'Submitted / Pending', value: 'submitted' },
        { label: 'Approved / Paid', value: 'approved' },
        { label: 'Cancelled', value: 'cancelled' },
        { label: 'Rejected', value: 'rejected' },
      ],

    }
  }
}
</script>

<style scoped>
</style>
